import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';

import CreateMeetingPage from 'components/CreateMeetingPage';
import diagramImage from '../assets/img/diagram.png';
import 'assets/scss/App.scss';


function Copyright(props) {
  return (
    <Typography variant="caption" display="block" color="text.secondary" align="center" {...props}>
      {`Copyright © `}{new Date().getFullYear()} Lumination Labs, Inc.
      <br/>
      Contact: support@stream2meeting.com
      <br/>
      <small>Not affiliated with Zoom, Zoom Video Communications, Inc., OBS or Teradek.</small>
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    mode: 'light',
    // mode: 'dark',
    primary: {
      main: '#3a7db3',
    },
    secondary: {
      main: '#d8b629',
    },
  },
});

export default hot(module)(function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          <Paper sx={{
            backgroundColor: '#eee',
         }}>
            <Box sx={{
              backgroundColor: '#181818',
              p: 1,
              pt: 0,
              pb: 3,
            }}>
              <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{ maxWidth: '100%', mt: 2, alignSelf: 'center', p: 2, borderRadius: 2, textAlign: 'center' }}
                >
                  <Box
                    component="img"
                    src={diagramImage}
                    sx={{ maxWidth: '80%', filter: 'drop-shadow(0 0 5px #000)' }}
                  />
                </Box>
                <Typography variant="h5" color="#eee" sx={{ mt: 2, fontWeight: 'bold', textAlign: 'center' }}>
                  Stream2Meeting
                </Typography>
                <Typography variant="body2" color="#eee" sx={{ textAlign: 'center' }}>
                  Easily Send <b>RTMP</b> Streams to <b>Zoom</b>
                </Typography>
                <Button size="small" href="#schedule" variant="contained" color="secondary" sx={{ mt: 2, mb: 1, backgroundColor: '#fd0' }} onClick={(e) => {
                  e.preventDefault();
                  const offsetTop = document.querySelector('a[name=schedule]').offsetTop;
                  scroll({
                    top: offsetTop,
                    behavior: "smooth"
                  });
                }}>
                  Schedule Now
                </Button>
                <Typography variant="body" color="#dda" sx={{ mt: 4, fontSize: '80%' }}>
                  <div style={{ fontSize: '140%' }}><b>Features</b></div>
                  <ul style={{ marginTop: 0, listStyle: 'none', color: '#eee', padding: 0 }}>
                    <li><span style={{ color: '#eee' }}>✓</span> Works with Zoom meetings and webinars</li>
                    <li><span style={{ color: '#eee' }}>✓</span> Relay streaming content from RTMP sources, including OBS &amp; Teradek devices</li>
                    <li><span style={{ color: '#eee' }}>✓</span> HD video quality (for supported Zoom plans and input streams)</li>
                    <li><span style={{ color: '#eee' }}>✓</span> Customizable offline image/message</li>
                    <li><span style={{ color: '#eee' }}>✓</span> Low latency: ~ 5 seconds average</li>
                    <li><span style={{ color: '#eee' }}>✓</span> Supports encrypted streams (RTMPS)</li>
                    <li><span style={{ color: '#eee' }}>✓</span> No signup or subscription required; only pay for what you need</li>
                    <li><span style={{ color: '#eee' }}>✓</span> Ability to schedule multiple meetings in bulk (only pay setup fee once)</li>
                  </ul>
                </Typography>
              </Container>
            </Box>
            <Container component="main" maxWidth="sm">
              <Box
                sx={{
                  pt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Routes>
                  <Route exact path="/" element={<CreateMeetingPage />} />
                  <Route exact path="/checkout_return/:sessionKey" element={<CreateMeetingPage />} />
                </Routes>
              </Box>
              <Copyright sx={{ mt: 6, pb: 2 }} />
            </Container>
          </Paper>
        </ConfirmProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
});
