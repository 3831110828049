import axios from 'axios';

export const createApi = () => {
  return axios.create({
    baseURL: API_BASE,
    timeout: 30000,
  });
};

export const formatCurrency = (input) => (
  `$${(Math.round(input * 100) / 100).toFixed(2)}`
);

export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ValidationError";
  }
}
