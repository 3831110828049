import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';
import moment from 'moment-timezone';

import { ValidationError } from '../util';

export default function ScheduleUpload({ timezone, zoomUrlRegex, emailRegex, onProcessedMeetings }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data) {
      try {
        const requiredFields = [
          'zoom_url',
          'start_date',
          'start_time',
          'duration_minutes',
        ];

        const header = data[0].map(f => f.trim().toLowerCase());
        if (requiredFields.some(rf => !header.includes(rf)) || !((header.includes('zoom_email') && header.includes('zoom_password')) || header.includes('zoom_display_name'))) {
          throw new ValidationError('The header row is missing at least one required column');
        }

        const meetings = [];
        data.slice(1).filter((d) => (d.length > 1)).forEach((d,i) => {
          const rowNum = i + 2;
          const zoom_url = d[header.indexOf('zoom_url')] || null;
          const zoom_email = d[header.indexOf('zoom_email')] || null;
          const zoom_password = d[header.indexOf('zoom_password')] || null;
          const zoom_screen_name = d[header.indexOf('zoom_display_name')] || null;
          const startDate = d[header.indexOf('start_date')] || null;
          const startTime = d[header.indexOf('start_time')] || null;
          const minutes_duration = parseInt(d[header.indexOf('duration_minutes')]);
          const sacrament_after_minutes = parseInt(d[header.indexOf('sacrament_after_minutes')]);
          const concluded_after_minutes = parseInt(d[header.indexOf('concluded_after_minutes')]);

          if (!zoom_url || !zoom_url.match(zoomUrlRegex)) {
            throw new ValidationError(`Row ${rowNum} contains an invalid Zoom URL`);
          }
          if (zoom_email && !zoom_email.match(emailRegex)) {
            throw new ValidationError(`Row ${rowNum} contains an invalid Zoom email address`);
          }

          let startDateTime;
          try {
            const startTimeMatches = startTime.trim().match(/(\d+:\d+)\s*(a|p)/);
            startDateTime = moment.tz([startDate.trim().replaceAll(/\D/g, '/'), startTimeMatches[1], startTimeMatches[2]].join(' '), 'M/D/YYYY h:m a', true, timezone);
            if (!startDateTime.isValid()) {
              throw new Error();
            }
          } catch (dateError) {
            throw new ValidationError(`Row ${rowNum} contains an invalid date/time`);
          }

          if (isNaN(minutes_duration) || (minutes_duration < 5) || (minutes_duration > (8 * 60))) {
            throw new ValidationError(`Row ${rowNum} contains an invalid duration`);
          }

          if (meetings.some((mtg) => {
            const start = moment.unix(mtg.start_time);
            const end = start.clone();
            end.add(mtg.minutes_duration, 'minutes');
            return startDateTime.isBetween(start, end);
          })) {
            throw new ValidationError(`Row ${rowNum} overlaps the time of a previous meeting`);
          }

          meetings.push({
            zoom_url,
            zoom_email: (zoom_email?.length > 0) ? zoom_email : null,
            zoom_password: (zoom_password?.length > 0) ? zoom_password : null,
            zoom_screen_name: (zoom_screen_name?.trim()?.length > 0) ? zoom_screen_name.trim() : null,
            start_time: startDateTime.unix(),
            minutes_duration,
            ...(!isNaN(sacrament_after_minutes) ? { sacrament_after_minutes } : {}),
            ...(!isNaN(concluded_after_minutes) ? { concluded_after_minutes } : {}),
          });
        });

        if (meetings.length < 1) {
          throw new ValidationError(`No meetings found in file`);
        }

        onProcessedMeetings(meetings);
      } catch (e) {
        setData(null);
        setError(e.name === 'ValidationError' ? e.message : 'Error parsing data. Please check the file and try again.');
      }
    }
  }, [data]);

  const clearData = () => {
    setData(null);
    setError(null);
    onProcessedMeetings([]);
  };

  if (error) {
    return (
      <Box>
        <Alert severity="error" sx={{ textAlign: 'left' }}>
          {error}
          <br/>
          <Button
            onClick={clearData}
            variant="text"
          >
            Try again
        </Button>
        </Alert>

      </Box>
    );
  }

  return (
    data ? (
      <Button
        onClick={clearData}
        variant="text"
      >
        Clear and Re-upload
      </Button>
    ) : (
      <CSVReader
        onUploadAccepted={(results) => {
          if (results.errors?.length > 0) {
            setError('Unable to read CSV file.');
          } else {
            setData(results.data);
          }
          
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
        }) => (
          <>
            <div
              {...getRootProps()}
              style={{
                alignItems: 'center',
                borderWidth: 2,
                borderStyle: 'dashed',
                borderColor: zoneHover ? '#686868' : '#aaa',
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                padding: 20,
                cursor: 'pointer',
              }}
            >
              {acceptedFile ? (
                <>
                  <div style={{
                    width: '100%',
                    padding: 10,
                  }}>
                    <ProgressBar />
                  </div>
                </>
              ) : (
                'Drop CSV file here or click to upload'
              )}
            </div>
          </>
        )}
      </CSVReader>
    )
  );
}