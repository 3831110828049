import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Search from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import _ from 'lodash';

import TrialDialog from './TrialDialog';
import TermsDialog from './TermsDialog';
import StreamInfoDialog from './StreamInfoDialog';
import ConfigurationTokenDialog from './ConfigurationTokenDialog';
import ConfirmDialog from './ConfirmDialog';
import ScheduleUpload from './ScheduleUpload';
import { createApi, formatCurrency, ValidationError } from '../util';
import exampleCsv from '../assets/example_meetings.csv'


// const PRICE_PER_MINUTE = 0.15;
const BASE_PRICE = 0;
const PRICE_PER_MINUTE = 0.10;
const MIN_START_FROM_NOW = 10;
const MAX_FUTURE_MONTHS = 13;
const DEFAULT_SCREEN_NAME = 'Stream2Meeting';
const CANCELED_SESSION_TOKEN = 'canceled';
const ZOOM_URL_REGEX = /^https:\/\/([\w\-]+\.)*zoom\.us\/j\/(\w+)\??((.+\W)?pwd=(\w+))?/i;
const EMAIL_REGEX = /^[\w_\-\.\+]+@([\w_\-]+\.)+\w+$/;
const USE_SECURE_COOKIES = API_BASE.toLowerCase().indexOf('https://') === 0;
const BASE_COOKIE_PARAMS = { path: '/', secure: USE_SECURE_COOKIES };

const api = createApi();

const nowDateTime = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + MIN_START_FROM_NOW);
  return now.toISOString().slice(0,16);
};

const calcPrice = (minutes) => (BASE_PRICE + (PRICE_PER_MINUTE * minutes));

export default function CreateMeetingPage() {
  const navigate = useNavigate();
  const { sessionKey: sessionKeyParam } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(['checkout_session', 'configuration_token']);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isWaitingCheckout, setIsWaitingCheckout] = useState(false);
  const [orderType, setOrderType] = useState('webinar');
  const [startDateTime, setStartDateTime] = useState(nowDateTime());
  const [editingStartDateTime, setEditingStartDateTime] = useState(false);
  const [hours, setHours] = useState(1);
  const [minutes, setMinutes] = useState(15);
  const [zoomUrl, setZoomUrl] = useState();
  const [useAccount, setUseAccount] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [zoomScreenName, setZoomScreenName] = useState(DEFAULT_SCREEN_NAME);
  const [zoomEmail, setZoomEmail] = useState();
  const [zoomPassword, setZoomPassword] = useState();
  const [multipleMeetings, setMultipleMeetings] = useState([]);
  const [useExistingToken, setUseExistingToken] = useState(false);
  const [configurationToken, setConfigurationToken] = useState();
  const [error, setError] = useState();
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const sessionKey = sessionKeyParam || cookies.checkout_session;


  let parsedStartDateTime;
  try {
    parsedStartDateTime = new Date(startDateTime);
  } catch (e) {
    parsedStartDateTime = new Date();
  }
  const soonThreshold = moment().add(15, 'minutes');
  const lateThreshold = moment().add(MAX_FUTURE_MONTHS, 'months');
  const startsSoon = (orderType === 'multiple') ? multipleMeetings.some(m => (moment().isBefore(moment.unix(m.start_time)) && soonThreshold.isAfter(moment.unix(m.start_time)))) : soonThreshold.isAfter(moment(parsedStartDateTime));
  const startsTooLate = (orderType === 'multiple') ? multipleMeetings.some(m => (moment().isBefore(moment.unix(m.start_time)) && lateThreshold.isBefore(moment.unix(m.start_time)))) : lateThreshold.isBefore(moment(parsedStartDateTime));

  const resetForm = () => {
    setZoomUrl(null);
    setZoomScreenName(DEFAULT_SCREEN_NAME);
    setZoomEmail(null);
    setZoomPassword(null);
    setAgreeTerms(false);
    setMultipleMeetings([]);
    setUseExistingToken(false);
    setConfigurationToken(null);
    setError(null);
  };

  useEffect(() => {
    const clockInterval = setInterval(() => {
      if (!editingStartDateTime && parsedStartDateTime < (new Date(nowDateTime()))) {
        setStartDateTime(nowDateTime());
      }
    }, 1000);
    return () => {
      clearInterval(clockInterval);
    }
  }, [editingStartDateTime, parsedStartDateTime]);



  useEffect(() => {
    let retryTimeout;
    (async () => {
      if (sessionKey && !isRedirecting) {
        setIsLoading(true);
        try {
          if (sessionKey.toLowerCase() === CANCELED_SESSION_TOKEN.toLowerCase()) {
            removeCookie('checkout_session', BASE_COOKIE_PARAMS);
            navigate('/');
          }
          const response = await api.get(`/orders/${sessionKey}`);
          // Responses: 404 error: session doesn't exist; 200 null configuration_token: not yet finalized; 200 w/ token: checkout complete
          if (response.data?.configuration_token) {
            removeCookie('checkout_session', BASE_COOKIE_PARAMS);
            setCookie('order_success', '1', BASE_COOKIE_PARAMS);
            if (response.data.is_new_token) {
              setCookie('is_new_token', '1', BASE_COOKIE_PARAMS);
            } else {
              removeCookie('is_new_token', BASE_COOKIE_PARAMS);
            }
            setCookie('configuration_token', response.data.configuration_token, BASE_COOKIE_PARAMS);
            setIsWaitingCheckout(false);
            setIsRedirecting(false);
            setIsLoading(false);
            setError(null);
            navigate('/');
          } else {
            // Try again after some time
            setIsWaitingCheckout(true);
            retryTimeout = setTimeout(() => setIsWaitingCheckout(false), 5000);
          }
        } catch (e) {
          if (e.response?.status === 404) {
            removeCookie('checkout_session', BASE_COOKIE_PARAMS);
            setError(null);
          } else {
            setError(`Checkout error: ${e.message}`);
          }
          setIsWaitingCheckout(false);
          setIsLoading(false);
          navigate('/');
        }
      }
    })();
    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [sessionKey, isRedirecting, isWaitingCheckout]);

  useEffect(() => {
    if (error) {
      setTimeout(() => document.getElementById('error_container')?.scrollIntoView(), 200);
    }
  }, [error]);


  const isValid = () => (
    agreeTerms &&
    !startsTooLate &&
    (!useExistingToken || (configurationToken?.length > 0)) &&(
      ((orderType === 'multiple') && (
        (multipleMeetings.length > 0) &&
        multipleMeetings.every((meeting) => (
          meeting.zoom_url && meeting.zoom_url.match(ZOOM_URL_REGEX) &&
          (!meeting.zoom_email || meeting.zoom_email.match(EMAIL_REGEX)) &&
          meeting.minutes_duration && (meeting.minutes_duration >= 5) && (meeting.minutes_duration <= (8 * 60))
        ))
      )) || (
        zoomUrl && zoomUrl.match(ZOOM_URL_REGEX) &&
        (((orderType === 'meeting') && !useAccount) || (zoomEmail && zoomPassword && zoomEmail.match(EMAIL_REGEX))) &&
        (((hours * 60) + minutes) >= 5) &&
        (hours <= 8) &&
        (minutes < 60)
      )
    )
  );

  const submitOrder = async () => {
    try {
      setIsLoading(true);
      setError(false);
      const response = await api.post('/orders', {
        configuration_token: useExistingToken ? configurationToken : null,
        ...((orderType === 'multiple') ? {
          meetings: multipleMeetings,
        } : {
          meetings: [{
            zoom_url: zoomUrl,
            zoom_screen_name: (zoomScreenName?.trim()?.length > 0) ? zoomScreenName.trim() : null,
            zoom_email: (zoomEmail?.length > 0) ? zoomEmail : null,
            zoom_password: (zoomPassword?.length > 0) ? zoomPassword : null,
            start_time: (new Date(startDateTime)).getTime() / 1000,
            minutes_duration: (hours * 60) + minutes,
          }]
        })
      });
      if (response.data?.session_key && response.data?.session_url) {
        setIsRedirecting(true);
        setTimeout(() => setIsWaitingCheckout(true), 5000);
        setCookie('checkout_session', response.data.session_key, { ...BASE_COOKIE_PARAMS, maxAge: 86400 * 7 });
        window.location.href = response.data.session_url;
        resetForm();
      } else if (response.data?.configuration_token) {
        setCookie('order_success', '1', BASE_COOKIE_PARAMS);
        if (response.data.is_new_token) {
          setCookie('is_new_token', '1', BASE_COOKIE_PARAMS);
        } else {
          removeCookie('is_new_token', BASE_COOKIE_PARAMS);
        }
        setCookie('configuration_token', response.data.configuration_token, BASE_COOKIE_PARAMS);
        setIsLoading(false);
        setError(null);
        resetForm();
      } else {
        throw new Error();
      }
    } catch (e) {
      if ((e.response?.status === 422) && (e.response?.data?.errors?.length > 0)) {
        setError(`Error: ${e.response.data.errors[0]}`);
      } else {
        setError('Invalid order. Please fix your parameters and try again.');
      }
    }
    setIsLoading(false);
  };

  if (isLoading || isWaitingCheckout || isRedirecting) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
        {isWaitingCheckout && (
          <Button
            variant="text"
            size="small"
            onClick={() => {
              removeCookie('checkout_session', BASE_COOKIE_PARAMS);
              setIsWaitingCheckout(false);
              setIsRedirecting(false);
              setIsLoading(false);
              setError(null);
              navigate('/');
            }}
            sx={{ mt: 2 }}
          >
            Cancel Order
          </Button>
        )}
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
        <ConfigurationTokenDialog
          trigger={(click) => (
            <Button onClick={click} size="small" startIcon={<Search/>}>
              View Existing Configuration
            </Button>
          )}
          onSubmit={(token) => {
            setCookie('configuration_token', token, BASE_COOKIE_PARAMS);
            setError(null);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container rowSpacing={2} sx={{ mb: 2 }}>
          <Container sx={{ alignItems: 'center' }}>
            <a name="schedule" />
            <Typography component="h1" variant="h6" sx={{ mt: 1 }} align="center" color="primary.dark">
              Schedule Your Live Stream Relay
            </Typography>
          </Container>
          <Grid item xs={12} align="center">
            <FormControl>
              <ButtonGroup variant="outlined">
                <Button variant={orderType === 'webinar' ? 'contained' : undefined} onClick={() => setOrderType('webinar')}>Webinar</Button>
                <Button variant={orderType === 'meeting' ? 'contained' : undefined} onClick={() => setOrderType('meeting')}>Meeting</Button>
                <Button variant={orderType === 'multiple' ? 'contained' : undefined} onClick={() => setOrderType('multiple')}>Multiple</Button>
              </ButtonGroup>
            </FormControl>
          </Grid>
          <Typography variant="body2" sx={{ mt: 3, mb: 3, fontSize: '80%' }} align="left">
            Schedule your {{ meeting: 'meeting', webinar: 'webinar', multiple: 'meetings/webinars' }[orderType]} in Zoom first, then schedule the stream relay below. Relay start times and durations are exact, so schedule the relay to begin early if needed.
          </Typography>
          {(orderType === 'multiple') ? (
            <>
              {(multipleMeetings.length <= 0) && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel required id="timezone-label">Time Zone</InputLabel>
                      <Select
                        required
                        labelId="timezone-label"
                        label="Time Zone"
                        value={timezone}
                        onChange={(e) => setTimezone(e.target.value)}
                      >
                        {moment.tz.names().map((tz) => (
                          <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} align="center" sx={{ mb: (multipleMeetings.length > 0) ? 0 : 2 }}>
                <ScheduleUpload
                  timezone={timezone}
                  zoomUrlRegex={ZOOM_URL_REGEX}
                  emailRegex={EMAIL_REGEX}
                  onProcessedMeetings={setMultipleMeetings}
                />
              </Grid>
              {(multipleMeetings.length > 0) ? (
                <>
                  <Grid item xs={12} sx={{ height: 300 }}>
                    <DataGrid
                      sx={{ boxShadow: 2, border: 1, borderColor: '#aaa' }}
                      rowHeight={35}
                      rowsPerPageOptions={[5]}
                      pageSize={5}
                      rows={multipleMeetings}
                      getRowId={(row) => JSON.stringify(row)}
                      columns={[
                        { type: 'dateTime', field: 'start_time', headerName: 'Start Time', width: 225, valueGetter: (props) => moment.unix(props.row.start_time).toDate(), valueFormatter: (params) => moment(params.value).utc().tz(timezone).format('lll (zz)') },
                        { type: 'number', field: 'minutes_duration', headerName: 'Duration', width: 100, valueFormatter: (params) => `${params.value} minutes` },
                        { type: 'string', field: 'zoom_url', headerName: 'Zoom URL', width: 250 },
                        ...(multipleMeetings.some(m => m.zoom_email) ? [{ type: 'string', field: 'zoom_email', headerName: 'Zoom Email', width: 200, valueFormatter: (params) => ((params.value?.length > 0) ? params.value : '(none)') }] : []),
                        ...(multipleMeetings.some(m => m.zoom_password) ? [{ type: 'string', field: 'zoom_password', headerName: 'Zoom Password', width: 150, valueFormatter: (params) => ((params.value?.length > 0) ? '********' : '(none)') }] : []),
                        ...(multipleMeetings.some(m => m.zoom_screen_name) ? [{ type: 'string', field: 'zoom_screen_name', headerName: 'Zoom Display Name', width: 150, valueFormatter: (params) => ((params.value?.length > 0) ? params.value : '(none)') }] : []),
                        ...(multipleMeetings.some(m => m.sacrament_after_minutes) ? [{ type: 'number', field: 'sacrament_after_minutes', headerName: 'Sacrament After', width: 150, valueFormatter: (params) => (params.value ? `${params.value} minutes` : '(none)') }] : []),
                        ...(multipleMeetings.some(m => m.concluded_after_minutes) ? [{ type: 'number', field: 'concluded_after_minutes', headerName: 'Concluded After', width: 150, valueFormatter: (params) => (params.value ? `${params.value} minutes` : '(none)') }] : []),
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography component="h1" align="center">
                      Total: <Typography component="span" color="primary.dark" sx={{ fontWeight: 'bold', fontSize: '120%' }}>{formatCurrency(calcPrice(_.sumBy(multipleMeetings, 'minutes_duration')))}</Typography>
                    </Typography>
                    <Typography component="p" align="center" fontSize="80%">
                      Total Duration: {_.sumBy(multipleMeetings, 'minutes_duration')} minutes
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Accordion sx={{ backgroundColor: 'primary.dark', mt: 1, color: 'primary.contrastText' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'primary.contrastText' }} />} sx={{ minHeight: 24, margin: 0 }}>
                      <Typography>CSV File Instructions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" sx={{ fontSize: '80%' }}>
                        Download and edit the example CSV file (below) in your favorite spreadsheet software. Then export as CSV and drop the file onto the area above.
                        <br/>
                        Columns must include:
                        <ul>
                          <li><b>zoom_url</b>: Zoom meeting/webinar invite link URL (required)</li>
                          <li><b>zoom_email</b>: Zoom login email address (required for webinars, optional for meetings)</li>
                          <li><b>zoom_password</b>: Zoom login password (required for webinars, optional for meetings)</li>
                          <li><b>zoom_display_name</b>: Zoom display name (optional, default: "Stream2Meeting")</li>
                          <li><b>start_date</b>: Date of meeting/webinar [month/day/year] (required)</li>
                          <li><b>start_time</b>: Time of meeting/webinar in selected time zone [hour:minute am/pm] (required)</li>
                          <li><b>duration_minutes</b>: Meeting length in minutes (required)</li>
                        </ul>
                        <br/>
                        If <b>zoom_email</b> and <b>zoom_password</b> are left blank, the meeting <u>must</u> be open to unauthenticated attendees, in which case the meeting will be joined using the <b>zoom_display_name</b>. If a meeting password is required, it must be included in the <b>zoom_url</b>.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Button
                    component="a"
                    href={exampleCsv}
                    target="_blank"
                    download
                    startIcon={<DownloadIcon/>}
                  >
                    Download Example
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Typography sx={{ fontWeight: 'bold', fontSize: '80%', mb: 2, marginLeft: 'auto', marginRight: 'auto' }}>Time &amp; Duration</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Start Time"
                    type={'datetime-local'}
                    value={startDateTime}
                    onChange={(e) => setStartDateTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { fontSize: '85%', height: 56 },
                      onFocus: () => setEditingStartDateTime(true),
                      onBlur: () => setEditingStartDateTime(false),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel required id="hours-label">Hours</InputLabel>
                    <Select
                      required
                      labelId="hours-label"
                      label="Hours"
                      value={hours}
                      onChange={(e) => setHours(e.target.value)}
                    >
                      {[...(minutes > 0 ? [0] : []),1,2,3,4,5,6,7,8].map((m) => (
                        <MenuItem key={m} value={m}>{m}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel required id="minutes-label">Minutes</InputLabel>
                    <Select
                      required
                      labelId="minutes-label"
                      label="Minutes"
                      value={minutes}
                      onChange={(e) => setMinutes(e.target.value)}
                    >
                      {[...(hours > 0 ? [0] : []), 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((m) => (
                        <MenuItem key={m} value={m}>{m}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h1" align="center">
                  Total: <Typography component="span" color="primary.dark" sx={{ fontWeight: 'bold', fontSize: '120%' }}>{formatCurrency(calcPrice((hours * 60) + minutes))}</Typography>
                </Typography>
                <Typography component="p" align="center" fontSize="80%">
                  {orderType === 'webinar' ? 'Webinar' : 'Meeting'} Duration: {[...(hours > 0 ? [`${hours} hour${hours === 1 ? '' : 's'}`] : []), ...(minutes > 0 ? [`${minutes} minute${minutes === 1 ? '' : 's'}`] : [])].join(', ')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={(orderType === 'webinar') ? 'Webinar Invite Link' : 'Meeting Invite Link'}
                  value={zoomUrl || ''}
                  onChange={(e) => setZoomUrl(e.target.value?.trim())}
                  autoComplete="url"
                  placeholder="https://zoom.us/j/1234567890?pwd=abcdefg"
                />
              </Grid>
              {(orderType === 'meeting') && (
                <FormControlLabel
                  sx={{ mt: 2 }}
                  control={<Switch sx={{ ml: 2 }} checked={useAccount} onChange={(e) => setUseAccount(e.target.checked)} color="primary" />}
                  label="Use a specific Zoom account"
                />
              )}
              {(useAccount || (orderType === 'webinar')) && (
                <>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        value={zoomEmail || ''}
                        onChange={(e) => setZoomEmail(e.target.value?.trim())}
                        label="Zoom User Email"
                        autoComplete="email"
                        placeholder="email@example.com"
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mb: 4 }}>
                      <TextField
                        required
                        fullWidth
                        value={zoomPassword || ''}
                        onChange={(e) => setZoomPassword(e.target.value?.trim())}
                        label="Zoom Password"
                        type="password"
                        autoComplete="password"
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: -5 }}>
                      <Typography align="right" sx={{ fontSize: '70%', opacity: 0.5 }} title="Transfered over secure connections and stored using industry-standard AES encryption. Passwords are deleted within 24 hours of use.">
                        <LockIcon sx={{ fontSize: '100%', marginBottom: '-2px' }} /> Encrypted, deleted after use
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              {((orderType === 'meeting') && !useAccount) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    value={zoomScreenName || ''}
                    onChange={(e) => setZoomScreenName(e.target.value?.replace(/[^\w \.\-_\(\),!\+=@&*]/, ''))}
                    label="Zoom Display Name"
                    placeholder="Display Name"
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sx={{ pl: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} color="primary" />}
              label={
                <Typography>{'I agree to the '}
                  <TermsDialog
                    trigger={(click) => (
                      <Link onClick={(e) => {
                        e.preventDefault();
                        click();
                      }}>Terms of Service</Link>
                    )}
                  />
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: -2, mb: 2, pl: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={useExistingToken} onChange={(e) => setUseExistingToken(e.target.checked)} color="primary" />}
            label="Use Existing Configuration"
          />
        </Grid>
        {useExistingToken && (
          <>
            <Typography variant="body2" sx={{ mt: 1, mb: 2, fontSize: '80%' }} align="left">
              This allows you to add the {{ meeting: 'meeting', webinar: 'webinar', multiple: 'meetings/webinars' }[orderType]} to a previous configuration so you don't have to change settings on your streaming device. NOTE: Concurrent {{ meeting: 'meetings', webinar: 'webinars', multiple: 'meetings/webinars' }[orderType]} must use separate configurations.
            </Typography>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <TextField
                required
                fullWidth
                value={configurationToken || ''}
                onChange={(e) => setConfigurationToken(e.target.value?.trim())}
                label="Configuration Token"
              />
            </Grid>
          </>
        )}

        {error && (
          <Alert id="error_container" severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>
        )}
        {startsTooLate && (
          <Alert id="error_container" severity="error" sx={{ mt: 2, mb: 2 }}>You cannot schedule a meeting for more than one year from now.</Alert>
        )}

        <Grid container>
          <Grid item xs={6}>
            <TrialDialog
              calcPrice={calcPrice}
              trigger={(click) => (
                <Button
                  variant="text"
                  size="small"
                  sx={{ justifyContent: 'flex-start', minWidth: 'auto' }}
                  onClick={click}
                >
                  Test Stream
                </Button>
              )}
            />
          </Grid>
          <Grid item xs={6} align="right">
            <StreamInfoDialog
              baseCookieParams={BASE_COOKIE_PARAMS}
              trigger={(click) => (
                <Button
                  variant="text"
                  size="small"
                  sx={{ justifyContent: 'flex-end', minWidth: 'auto' }}
                  onClick={click}
                >
                  Preview
                </Button>
              )}
            />
          </Grid>
        </Grid>
        <ConfirmDialog
          title="Confirm Start Time"
          paragraphs={[
            <span>You've scheduled a meeting that begins soon. <b>5 minutes</b> is required between the time you complete payment (on the next screen) and when the stream can start, at the earliest.</span>,
            <span>If your stream is scheduled to start earlier than 5 minutes from the moment payment is completed, you may experience a delay while the stream relay is prepared for operation.</span>,
          ]}
          submitLabel="Continue"
          onSubmit={submitOrder}
          trigger={(click) => (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              disabled={!isValid()}
              onClick={startsSoon ? click : submitOrder}
            >
              Schedule Stream{(orderType === 'multiple' && (multipleMeetings?.length > 0)) ? 's' : ''} <ChevronRight />
            </Button>
          )}
        />
        <Typography variant="body2" align="center" sx={{ fontWeight: 'bold', fontSize: '80%', color: '#555', mt: 2 }}>
          Special pricing available for church and other nonprofit broadcasts - <a href="mailto:support@stream2meeting.com" style={{ color: 'inherit' }}>Contact For Details</a>
        </Typography>
      </Box>
    </>
  )
}