import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

export default function FallbackTextDialog({ trigger, onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('the stream source is currently offline');

  return (
    <div>
      {trigger(() => setIsOpen(true))}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Custom Offline Text</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This text will be shown in the Zoom meeting while the RTMP source stream is offline.
          </DialogContentText>
          <DialogContentText sx={{ fontSize: '80%', mt: 1 }}>
            Note: If you've uploaded a custom offline image, this will replace it.
          </DialogContentText>
          <TextField
            fullWidth
            multiline
            value={value || ''}
            onChange={e => setValue(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => {
            setIsOpen(false);
            onSubmit(value);
          }}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
