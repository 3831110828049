import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfigurationTokenDialog({ trigger, onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState('');

  return (
    <div>
      {trigger(() => setIsOpen(true))}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Configuration Lookup</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you've already scheduled a stream relay, you can use your configuration token to look up your configuration.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Configuration Token"
            fullWidth
            variant="standard"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => {
            onSubmit(token?.trim());
            setIsOpen(false);
          }}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
