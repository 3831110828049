import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { useCookies } from 'react-cookie';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import { useConfirm } from 'material-ui-confirm';

import { createApi, formatCurrency, ValidationError } from '../util';
import MeetingControl from './MeetingControl';

const api = createApi();

const PLACEHOLDERS = {
  autoConfig: 'https://config.example.com/remote/12345',
  rtmp: 'rtmp://stream.example.com/live',
  streamKey: 'cfmvfshh2uklum7n',
};

const CopyableTextField = ({ value, ...otherProps }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => setShowTooltip(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showTooltip]);


  return (
    <TextField
      readOnly
      fullWidth
      value={value || ''}
      InputProps={{
        onClick: (e) => e.target.select?.(),
        endAdornment: (
          <Tooltip
            TransitionComponent={Zoom}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setShowTooltip(false)}
            open={showTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
          >
            <InputAdornment position="end">
              <IconButton
                aria-label="Copy this value"
                title="Copy this value"
                onClick={() => {
                  navigator.clipboard.writeText(value);
                  setShowTooltip(true);
                }}
                edge="end"
              >
                <ContentPaste />
              </IconButton>
            </InputAdornment>
          </Tooltip>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...otherProps}
    />
  );
};


export default function StreamInfoDialog({ trigger, baseCookieParams }) {
  const [cookies, setCookie, removeCookie] = useCookies(['configuration_token', 'order_success', 'is_new_token']);
  const confirm = useConfirm();
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [error, setError] = useState(null);
  const { rtmp_url: rtmpUrl, autoconfig_url: autoConfigUrl, stream_key: streamKey, current_meeting_id: currentMeetingId, next_meeting_in: nextMeetingIn, meetings } = configuration || {};
  const isOpen = !!(showPreview || cookies.configuration_token);

  const close = async () => {
    try {
      if (configuration && cookies.is_new_token) {
        await confirm({
          allowClose: false,
          title: 'Are you sure?',
          description: 'Once this message is closed, you will not have another chance to retrieve your configuration token. Continue only if you\'ve saved it in a secure place.',
        });
      }
      setShowPreview(false);
      setConfiguration(null);
      setError(null);
      removeCookie('configuration_token', baseCookieParams);
      removeCookie('order_success', baseCookieParams);
      removeCookie('is_new_token', baseCookieParams);
    } catch (e) {
      // Do nothing
    }
  };

  const downloadToken = () => {
    const element = document.createElement('a');
    const file = new Blob([`Stream2Meeting Configuration Token: ${cookies.configuration_token}`], { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = 'stream2meeting_token.txt';
    document.body.appendChild(element);
    element.click();
  };

  const fetchInfo = async (showLoading = true) => {
    if (cookies.configuration_token) {
      if (showLoading) setIsLoading(true);
      try {
        const response = await api.get(`/configurations/${cookies.configuration_token}`);
        if (response.data?.meetings) {
          setConfiguration(response.data);
        } else {
          throw new Error();
        }
      } catch (e) {
        setError('Invalid configuration token');
      }
      if (showLoading) setIsLoading(false);
    } else {
      close();
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [cookies.configuration_token]);



  return (
    <>
      {trigger(() => setShowPreview(true))}
      <Dialog
        open={isOpen}
        onClose={close}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{showPreview ? 'Preview Configuration' : 'Stream Configuration'}</DialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', p: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            error ? (
              <Box sx={{ display: 'flex' }}>
                <Alert severity="error">{error}</Alert>
              </Box>
            ) : (
              <Box component="form">
                <Grid container spacing={2}>
                  {(cookies.configuration_token && cookies.order_success) && (
                    <>
                      <Grid item xs={12}>
                        <Alert severity="success">Your order is complete!</Alert>
                      </Grid>
                      {cookies.is_new_token && (
                        <Grid item xs={12}>
                          <Paper sx={{ backgroundColor: '#fff8d6', p: 2, border: '2px solid', mb: 2 }}>
                            <Typography variant="h6" align="center" color="#a52">
                              IMPORTANT
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                              Your configuration token is <b>required</b> in order to retrieve your configuration settings (below) at a later time. It <b>will not be shown again</b>. Please <b>save it now</b> in a safe place, and do not share it publicly.
                            </Typography>
                            <CopyableTextField
                              label="Configuration Token"
                              value={cookies.configuration_token}
                              variant="filled"
                            />
                            <Button onClick={downloadToken}>
                              Download Configuration Token
                            </Button>
                          </Paper>
                        </Grid>
                      )}
                    </>
                  )}
                  {showPreview && (
                    <Typography variant="body2" sx={{ m: 2, mb: 3 }}>
                      <b>The following configuration settings are for illustrative purposes only.</b> After scheduling a stream, your actual configuration will differ.
                    </Typography>
                  )}
                  <Grid item xs={12}>
                    <CopyableTextField
                      label="Remote XML Configuration URL (Teradek Only)"
                      value={showPreview ? PLACEHOLDERS['autoConfig'] : autoConfigUrl}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center">Manual Configuration:</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <CopyableTextField
                      label="RTMP URL"
                      value={showPreview ? PLACEHOLDERS['rtmp'] : rtmpUrl}
                    />
                    <Typography variant="body2" fontSize="70%">
                      RTMPS is also supported - just replace <b>rtmp://</b> with <b>rtmps://</b>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <CopyableTextField
                      label="Stream Key"
                      value={showPreview ? PLACEHOLDERS['streamKey'] : streamKey}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2" fontSize="70%">
                      NOTE: The <strong>Stream Key</strong> will be appended to the <strong>RTMP(S) URL</strong>, separated by a slash.
                    </Typography>
                    <Typography variant="body2" fontSize="70%">
                      Many applications require them to be configured separately, but some require them to be combined.
                    </Typography>
                    <Typography variant="body2" fontSize="70%">
                      Example: {showPreview ? PLACEHOLDERS['rtmp'] : rtmpUrl}/<strong>{showPreview ? PLACEHOLDERS['streamKey'] : streamKey}</strong>
                    </Typography>
                  </Grid>
                  {!showPreview && (currentMeetingId || nextMeetingIn) && <MeetingControl baseCookieParams={baseCookieParams} token={cookies.configuration_token} meetingId={currentMeetingId} zoomMeetingId={currentMeetingId && (meetings || []).find(m => (m.id === currentMeetingId))?.zoom_meeting_id} nextMeetingIn={nextMeetingIn} onRefreshNeeded={() => fetchInfo(false)} />}
                  {(meetings && (meetings.length > 0)) && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: -1 }}>
                          Scheduled Meeting Relays
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ height: 300 }}>
                        <DataGrid
                          rowHeight={35}
                          rowsPerPageOptions={[5]}
                          pageSize={5}
                          rows={meetings}
                          sx={{ boxShadow: 2, border: 2 }}
                          columns={[
                            { type: 'dateTime', field: 'start_time', headerName: 'Start Time', width: 250, valueGetter: (props) => moment.unix(props.row.start_time).toDate(), valueFormatter: (params) => moment(params.value).utc().tz(moment.tz.guess()).format('lll (zz)') },
                            { type: 'number', field: 'end_time', headerName: 'Duration', width: 100, valueGetter: (props) => ((props.row.end_time - props.row.start_time) / 60), valueFormatter: (params) => `${params.value} minutes` },
                            { type: 'string', field: 'zoom_meeting_id', headerName: 'Meeting ID', width: 150 },
                          ]}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
