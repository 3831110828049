import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({ trigger, title, paragraphs, submitLabel = 'Submit', onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {trigger(() => setIsOpen(true))}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {paragraphs.map((paragraph, i) => (
            <DialogContentText key={i} sx={{ mt: (i > 0) ? 2 : 0 }}>
              {paragraph}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => {
            setIsOpen(false);
            onSubmit();
          }}>{submitLabel}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
