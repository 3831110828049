import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import DangerousIcon from '@mui/icons-material/Dangerous';
import RefreshIcon from '@mui/icons-material/Refresh';
import MicIcon from '@mui/icons-material/Mic';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArticleIcon from '@mui/icons-material/Article';
import { useCookies } from 'react-cookie';
import { useConfirm } from 'material-ui-confirm';
import ReconnectingEventSource from 'reconnecting-eventsource';
import moment from 'moment-timezone';
import loadImage from 'blueimp-load-image';

import CircularProgressWithLabel from './CircularProgressWithLabel';
import FallbackTextDialog from './FallbackTextDialog';
import ConfirmDialog from './ConfirmDialog';
import { createApi, ValidationError } from '../util';


const api = createApi();

const STANDARD_REFRESH_TIME = 120000;
const MEETING_CLOSE_MINUTES = 10;
const MAX_IMAGE_DIMENSION = 1920;
const EXPECTED_BOOT_TIME_SECONDS = 120;
const VIRTUAL_PROGRESS_START = 20;
const VIRTUAL_PROGRESS_END = 55;
const REAL_PROGRESS_CONTINUE = 60;


const STARTING_PROGRESS = {
  starting1: 5,
  starting2: 10,
  starting3: 15,
  starting4: 20,
  starting5: 60,
  starting6: 70,
  starting7: 85,
  meeting_started: 100,
};

const preprocessImageBase64 = (file, options) => {
  const { format, maxDimension } = options;
  return new Promise((resolve) => {
    loadImage(file, (canvas) => {
      const b64 = canvas.toDataURL(format, 0.5);
      resolve(b64.substr(b64.indexOf(',') + 1));
    }, {
      canvas: true,
      maxWidth: maxDimension,
      maxHeight: maxDimension,
      orientation: true,
    });
  });
};

export default function MeetingControl({ baseCookieParams, token, meetingId, zoomMeetingId, nextMeetingIn, onRefreshNeeded }) {
  const [cookies, setCookie, removeCookie] = useCookies(['provisioning_progress']);
  const confirm = useConfirm();
  const [realProgress, setRealProgress] = useState(0);
  const [virtualProgress, setVirtualProgress] = useState((cookies.provisioning_progress && parseInt(cookies.provisioning_progress)) || VIRTUAL_PROGRESS_START);
  const [rtmpConnected, setRtmpConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(false);
  const [nonFatalError, setNonFatalError] = useState(false);
  const [stopping, setStopping] = useState(false);
  const [finished, setFinished] = useState(false);
  const [nextMeetingAt, setNextMeetingAt] = useState(null);
  const [nextMeetingInText, setNextMeetingInText] = useState(null);
  const [pendingAction, setPendingAction] = useState(false);
  const [actionError, setActionError] = useState(null);
  const durationLeft = (t) => (t ? moment.duration(t.diff(moment())) : null);
  const progress = (realProgress === VIRTUAL_PROGRESS_START) ? virtualProgress : realProgress;

  useEffect(() => {
    if (token && meetingId) {
      setIsConnecting(true);
      const es = new ReconnectingEventSource(`${API_BASE}/configurations/${token}/meetings/${meetingId}/status`);
      es.onmessage = ({ data: rawData }) => {
        setIsConnecting(false);
        if (rawData === 'SSE_CLOSE_CHANNEL') {
          es.close();
        } else {
          const data = JSON.parse(rawData);
          if (data.meeting) {
            setRealProgress(STARTING_PROGRESS[data.meeting] || 0);
            setNonFatalError(data.meeting === 'non_fatal_error');
            setError(data.meeting === 'error');
            setStopping(data.meeting === 'stopping');
            setFinished(data.meeting === 'finished');
            if (data.meeting === 'starting5') setRtmpConnected(false); // Config requested
          } else if (data.rtmp) {
            setRtmpConnected(data.rtmp === 'rtmp_streaming');
          }
        }
      };
      const timeout = setTimeout(() => setIsConnecting(false), 5000);
      return () => {
        es.close();
        clearTimeout(timeout);
      }
    }
  }, [token, meetingId]);

  useEffect(() => {
    if (error) {
      confirm({
        allowClose: false,
        cancellationButtonProps: { sx: { display: 'none' } },
        title: 'Relay Terminated',
        description: 'Unfortunately the stream relay was terminated due to an unrecoverable error. Please check your Zoom configuration and try again.',
      });
    }
  }, [error]);

  useEffect(() => {
    if ((progress >= VIRTUAL_PROGRESS_START) && (progress < VIRTUAL_PROGRESS_END)) {
      setCookie('provisioning_progress', progress, baseCookieParams);
      const timer = setTimeout(() => setVirtualProgress(progress + 1), (EXPECTED_BOOT_TIME_SECONDS / (VIRTUAL_PROGRESS_END - VIRTUAL_PROGRESS_START)) * 1000);
      return () => clearTimeout(timer);
    } else if ((progress < VIRTUAL_PROGRESS_START) || (progress >= REAL_PROGRESS_CONTINUE)) {
      removeCookie('provisioning_progress', baseCookieParams);
    }
  }, [progress]);

  useEffect(() => {
    setNextMeetingAt(nextMeetingIn ? moment().add(nextMeetingIn, 'seconds') : null);
  }, [nextMeetingIn]);

  useEffect(() => {
    const updateTimeLeft = () => setNextMeetingInText(durationLeft(nextMeetingAt)?.humanize());
    updateTimeLeft();
    const interval = setInterval(updateTimeLeft, 10000);
    return () => clearInterval(interval);
  }, [nextMeetingAt]);

  useEffect(() => {
    const minutesLeft = Math.max(durationLeft(nextMeetingAt)?.asMinutes(), 0);
    let delay = STANDARD_REFRESH_TIME;
    if (minutesLeft <= MEETING_CLOSE_MINUTES) delay = STANDARD_REFRESH_TIME / 2;
    if (error) delay = STANDARD_REFRESH_TIME * 2;
    const interval = setInterval(() => {
      if ((progress <= 0) || finished || error) {
        onRefreshNeeded();
      }
    }, delay);
    return () => clearInterval(interval);
  }, [progress, nextMeetingAt, finished, error]);


  const doAction = async (command, e) => {
    if (pendingAction) return;
    setPendingAction(true);
    setActionError(null);

    const params = {};
    if (command === 'setImage' && (e?.target?.files?.length === 1)) {
      params.image_data = await preprocessImageBase64(e.target.files[0], {
        format: 'image/jpeg',
        maxDimension: MAX_IMAGE_DIMENSION,
      });
    } else if (command === 'setImageText') {
      params.text = e;
    }
    
    try {
      const response = await api.post(`/configurations/${token}/meetings/${meetingId}/command`, { command, params });
      if (response.data?.success) {
        await new Promise(r => setTimeout(r, (response.data.delay || 10) * 1000));
      } else {
        throw new Error();
      }
    } catch (e) {
      if ((e.response?.status === 422) && (e.response?.data?.errors?.length > 0)) {
        setActionError(`Error: ${e.response.data.errors[0]}`);
      } else {
        setActionError('Error executing action');
      }
      setTimeout(() => setActionError(null), 5000);
    }
    setPendingAction(false);
  };

  let content = (
    <Box sx={{ mt: 2 }}>
      <CircularProgress color="secondary" />
    </Box>
  );

  if (meetingId && !isConnecting) {
    content = (
      <>
        <Typography variant="body2" sx={{ color: '#fff' }}>Preparing RTMP Relay... </Typography>
        <Box sx={{ mt: 2 }}>
          {(progress > 0) ? <CircularProgressWithLabel value={progress} color="secondary" textColor="#fff" /> : <CircularProgress color="secondary" />}
        </Box>
      </>
    );
  }

  if (!meetingId && nextMeetingInText) {
    content = (
      <>
        <Typography variant="body2" sx={{ color: '#fff' }}>Next Meeting In: {nextMeetingInText}</Typography>
        <Typography variant="body2" sx={{ color: '#ccc' }}>(meeting controls will appear here)</Typography>
      </>
    );
  }

  if (progress >= 100) {
    content = (
      <>
        <Typography variant="body2" sx={{ color: '#fff' }}><b>Meeting Started</b> (ID: {zoomMeetingId})</Typography>
        <Typography variant="body2" sx={{ color: '#eee' }}>Source Status: <span style={{ fontWeight: 'bold', color: rtmpConnected ? '#28f91f' : '#ff7777' }}>{rtmpConnected ? 'Streaming' : 'Offline'}</span></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
          <Chip disabled={pendingAction} onClick={() => doAction('toggleMute')} icon={<MicIcon />} label="Mute/Unmute" variant="contained" color="info" sx={{ m: 1 }} />
          <ConfirmDialog
            title="Are you sure?"
            paragraphs={['This can take several minutes to complete and may interrupt the Zoom meeting. Only continue if the meeting has stopped or is experiencing serious issues.']}
            submitLabel="Continue"
            onSubmit={() => doAction('restartMeeting')}
            trigger={(click) => <Chip disabled={pendingAction} onClick={click} icon={<RefreshIcon />} label="Restart Relay" variant="contained" color="info" sx={{ m: 1 }} />}
          />
          <ConfirmDialog
            title="Are you sure?"
            paragraphs={[<span>This will end the meeting and stop the relay. You will <b>NOT</b> be able to restart the relay once you've stopped it.</span>]}
            submitLabel="Continue"
            onSubmit={() => doAction('stopMeeting')}
            trigger={(click) => <Chip disabled={pendingAction} onClick={click} icon={<DangerousIcon />} label="End Meeting" variant="contained" color="info" sx={{ m: 1 }} />}
          />
          <label htmlFor="contained-button-file">
            <input disabled={pendingAction} accept="image/*" onChange={(e) => doAction('setImage', e)} id="contained-button-file" type="file" style={{ display: 'none' }} />
            <Chip disabled={pendingAction} onClick={() => {}} icon={<ArrowCircleUpIcon />} component="span" label="Upload Offline Image" variant="contained" color="info" sx={{ m: 1 }} />
          </label>
          <FallbackTextDialog
            trigger={(click) => (
              <Chip disabled={pendingAction} onClick={click} icon={<ArticleIcon />} label="Set Offline Text" variant="contained" color="info" sx={{ m: 1 }} />
            )}
            onSubmit={(value) => doAction('setImageText', value)}
          />
        </Box>
        {pendingAction && <Typography variant="body2" sx={{ color: '#fff' }}>Executing command...</Typography>}
        {actionError && <Alert severity="error">{actionError}</Alert>}
      </>
    );
  }

  if (stopping) {
    content = <Typography variant="body2" sx={{ color: '#fff' }}>Stopping Meeting...</Typography>;
  }

  if (finished) {
    content = (
      <>
        <CheckCircleIcon sx={{ color: '#fff' }} />
        <Typography variant="body2" sx={{ color: '#fff' }}>Meeting Concluded</Typography>
      </>
    );
  }

  if (nonFatalError) {
    content = (
      <>
        <Typography variant="body2" sx={{ color: '#fff' }}>Meeting Error &mdash; Attempting To Recover...</Typography>
        <Box sx={{ mt: 2 }}>
          <CircularProgress color="secondary" />
        </Box>
      </>
    );
  }

  if (error) {
    content = (
      <>
        <WarningIcon sx={{ color: '#fff' }} />
        <Typography variant="body2" sx={{ color: '#fff', fontWeight: 'bold' }}>Unrecoverable Meeting Error</Typography>
        <Typography variant="body2" sx={{ color: '#eee' }}>Double-check your Zoom credentials and try again with another meeting.</Typography>
      </>
    );
  }

  return content && (
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#0e3b63', borderRadius: 4, p: 2, mt: 2, mb: 1 }}>
        {content}
      </Box>
    </Grid>
  );
};
